import Head from "next/head"
import React from "react"

import Layout from "../components/Layout"
import OffersPage from "../components/OffersPage"
import { ContentPage, isContentActive } from "../components/contentful"
import { getPageContents } from "../components/contentful/pageProps"
import { getEntriesByType, getAllPromos } from "../util/contentful/api"
import { handleError } from "../util/handlers"
import FourOhFour from "./404"

const ApiPromo = ({ content }) => {
    return (
        <>
            <Head>
                <meta name="robots" content="noindex, nofollow"></meta>
                <meta name="googlebot" content="noindex,nofollow" />
            </Head>
            <Layout>
                <OffersPage offerDetails={content} />
            </Layout>
        </>
    )
}

export default function Page({ type, content, preview, breadcrumbs }) {
    const isPageActive = content && isContentActive({ ...content, preview })
    // Passing content so promotions SEO values pass to the 404 (noindex,nofollow)
    if (!isPageActive) return <FourOhFour content={content} />

    let pageComponent

    switch (type) {
        case "apiPromo":
            pageComponent = <ApiPromo {...{ content }} />
            break
        default:
            pageComponent = (
                <ContentPage {...{ content, preview, breadcrumbs }} />
            )
    }

    return pageComponent
}

export const getStaticPaths = async ({ defaultLocale }) => {
    let paths = []

    try {
        const promos = await getAllPromos({ defaultLocale })
        const pages = await getEntriesByType("page", {
            resolveLinks: false,
            include: 0,
            "metadata.tags.sys.id[nin]": "doNotGenerate",
            // Need to ensure blog is not included in static paths
            // otherwise getServerSideProps is not executed within blog/index.js
            "fields.path[nin]": "/blog",
            select: "fields.path",
        })

        const pagePaths = pages.items.map(({ fields: { path } }) => ({
            params: {
                path: path.slice(1).split("/"),
            },
        }))

        const promoPaths = promos.map(({ path, slug }) => {
            return {
                params: {
                    path: path?.slice(1).split("/") || [slug],
                },
            }
        })

        paths = [...promoPaths, ...pagePaths]
    } catch (err) {
        console.log("Static Path Error [[path]]")
        handleError(err)
    }

    return {
        paths,
        // paths: pagePaths,
        // paths: [], // don't pre-render path; all pages should be rendered on-demand
        fallback: "blocking", // render the page on first request
    }
}

export async function getStaticProps({ params, ...props }) {
    const { path = [] } = params
    const { defaultLocale, preview } = props

    const urlPath = path.join("/")
    const promos = await getAllPromos({ defaultLocale, preview })
    const promo = promos.find(({ path }) => path.toLowerCase() === `/${urlPath.toLowerCase()}`)

    const type = promo ? "promo" : "page"

    return await getPageContents(
        {
            ...props,
            breadcrumbs: type === "promo" || urlPath === "" ? [] : null,
        },
        {
            type,
            // [match] is case insensitive, but can pull up multiple matches. 
            // Additional checks need to happen when fetching pages from Contentful
            filterField: "fields.path[match]",
            filterValue: `/${urlPath}`,
        }
    )
}
