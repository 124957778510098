/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Grid, Typography } from "@mui/material"
import React from "react"

import { STATIC_IMAGE_PATH } from "../../util"
import Link from "../Link"
import Slide from "../Slide"

function Services({ offerDetails }) {
    const SERVICES_LIST = [
        {
            imgSrc: `${STATIC_IMAGE_PATH}services/paint-service-tape.jpg`,
            title: "Taped off vehicle",
            title1: "Easy & Affordable",
            title2: " Car Painting",
            link: "services/auto-painting",
            linkTitle: "Easy & Affordable Car Painting",
            content: (
                <Typography component="span">
                    {`With our easy and affordable paint packages, there's no better time to get your car looking like new again.`}
                    {offerDetails &&
                        !offerDetails["New URL"].includes(
                            "499-overall-paint-sale"
                        ) && (
                            <Typography
                                component="span"
                                variant="body1"
                                css={css`
                                    display: inline-block;
                                    margin-top: 5px;
                                    line-height: 1.2rem;
                                `}
                                className="xs-block"
                                title="Online Estimator"
                            >
                                <Link href="/online-estimator" noLinkStyle>
                                    Schedule a free estimate today
                                </Link>
                            </Typography>
                        )}
                </Typography>
            ),
            sm: 4,
        },
        {
            imgSrc: `${STATIC_IMAGE_PATH}services/service-cosmetic-car-repairs.jpg`,
            title: "Employee using grinder for repair",
            title1: "Uh-oh. Better Get Maaco.",
            title2: "Collision Repair",
            link: "services/collision-repair",
            linkTitle: "Uh-oh. Better Get Maaco.Collision Repair",
            content: (
                <Typography component="span">
                    {`Maaco's expertly trained technicians can repair your dents,dings and accidental damage to get you back on the road quickly.`}
                    <br />
                    <br />
                    <Typography component="span">
                        {`We service over 500,000 cars annually - with all repairs backed by our great `}
                        <Typography
                            component="span"
                            css={css`
                                display: inline-block;
                                margin-top: 5px;
                                line-height: 1.2rem;
                            `}
                            className="xs-block"
                            title="Warranty Policy"
                            display="inline"
                        >
                            <Link href="/warranty-policy" noLinkStyle>
                                nationwide warranty.
                            </Link>
                        </Typography>
                    </Typography>
                </Typography>
            ),
            sm: 4,
        },
        {
            imgSrc: `${STATIC_IMAGE_PATH}services/repair-program-drm.jpg`,
            title: "Employee sanding vehicle",
            title1: "Insurance Claims/Direct",
            title2: "Repair Program (DRP)",
            link: "services/insurance-claims",
            linkTitle: "Insurance Claims/Direct Repair Program (DRP)",
            content: (
                <Typography component="span">
                    {`20 million drivers have trusted Maaco's easy and affordable body shops - from minor damage to large repairs.`}
                    <br />
                    <br />
                    <Typography component="span">
                        {`We work with all major insurance companies to schedule, inspect and repair your vehicle.`}
                        {offerDetails &&
                            !offerDetails["New URL"].includes(
                                "499-overall-paint-sale"
                            ) && (
                                <Typography
                                    variant="body1"
                                    component="span"
                                    css={css`
                                        display: inline-block;
                                        margin-top: 5px;
                                        line-height: 1.2rem;
                                    `}
                                    className="xs-block"
                                    title="Get an estimate today"
                                    display="inline"
                                >
                                    <Link href="/online-estimator" noLinkStyle>
                                        Get an estimate today
                                    </Link>
                                </Typography>
                            )}
                    </Typography>
                </Typography>
            ),
            sm: 4,
        },
    ]
    return (
        <React.Fragment>
            <Grid
                item
                xs={12}
                container
                alignItems="flex-start"
                justifyContent="center"
                direction="column"
                style={{ margin: "1vh 0 2vh 0" }}
            >
                <Typography variant="h4" color="primary">
                    MAACO SERVICES
                </Typography>
                <Typography variant="p" lineHeight="20px">
                    {`See the potential in your car. With our attention to detail,
                    you'll fall in love with your car all over again.`}
                </Typography>
                <br></br>
            </Grid>
            <Grid item xs={12} container>
                <div>
                    <Grid
                        container
                        css={css`
                            @media (max-width: 737px) {
                                width: 100% !important;
                                margin: 0 !important;
                                & > div {
                                    padding: 0 0px 25px 0px;
                                }
                            }
                        `}
                    >
                        {SERVICES_LIST.map((i, k) => (
                            <Slide
                                key={k}
                                imgSrc={i.imgSrc}
                                title={i.title}
                                title1={i.title1}
                                title2={i.title2}
                                link={i.link}
                                linkTitle={i.linkTitle}
                                content={i.content}
                            />
                        ))}
                    </Grid>
                </div>
            </Grid>
        </React.Fragment>
    )
}

export default Services
