/** @format */

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { makeStyles } from "@mui/styles"
import { HighlightOff } from "@mui/icons-material"
import { Box, Button, Grid, Modal, Typography } from "@mui/material"
import React, { useState } from "react"

import { containerCSS, couponPayload } from "../../util"
import OptimizedImage from "../OptimizedImage"
import Phone from "../SendCoupon/StandardOffersPhone"
import Email from "../SendCoupon/StandardOffersEmail"
import ScheduleAppointment from "../ScheduleAppointment"
import Seo from "../SEO"
import { EMAIL_MSG, TEXT_MSG } from "../util"
import Services from "./Services"

const useStyles = makeStyles({
    root: {
        minWidth: 345,
    },
    media: {
        height: 140,
    },
    text: {
        width: "60rem",
        textAlign: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 3,
        fontSize: "3.75rem",
        color: "#fff",
        letterSpacing: 0,
        lineHeight: "5rem",
        textShadow: "0 2px 4px rgb(0 0 0 / 80%);",
        "@media (max-width: 1200px)": {
            fontSize: "2rem",
        },
        "@media (max-width: 650px)": {
            fontSize: "1.25rem",
            lineHeight: "1rem",
            width: "100%",
        },
    },
    title: {
        fontSize: "3.5rem",
        lineHeight: "3.75rem",
        "@media (max-width: 1200px)": {
            fontSize: "2rem",
            lineHeight: "2.5rem",
        },
        "@media (max-width: 650px)": {
            fontSize: "1.5rem",
            lineHeight: "1.75rem",
            width: "100%",
        },
    },
    offerButton: {
        paddingTop: "3px",
    },
    offerContainer: {
        "@media (min-width: 650px)": {
            display: "flex",
            justifyContent: "center",
        },
        textAlign: "center",
    },
})

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    "@media (max-width: 650px)": {
        width: "80%",
    },
}

const OffersPage = ({ offerDetails }) => {
    const classes = useStyles()
    const [openEmail, setOpenEmail] = useState(false)
    const handleOpenEmail = () => setOpenEmail(true)
    const handleCloseEmail = () => setOpenEmail(false)

    const [openText, setOpenText] = useState(false)
    const handleOpenText = () => setOpenText(true)
    const handleCloseText = () => setOpenText(false)

    return (
        <>
            <Seo
                title={`Maaco Auto Body Shop & Painting | Offers`}
                description={`Find offers at a Maaco near me that does auto painting, auto body restoration, and collision repair near me`}
                noIndex
            />
            <Grid item xs={12} position="relative" height="auto">
                <OptimizedImage
                    src={offerDetails["banner"]}
                    alt="50th Anniversay Banner Image"
                    style={{
                        zIndex: "2",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}
                />
            </Grid>
            <Grid css={css(containerCSS)}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Typography
                            align="center"
                            className={classes.title}
                            color="#214e70"
                            component="h1"
                            variant="h1"
                        >
                            {offerDetails["H1 Title"] ?? offerDetails.title}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Grid
                            css={css`
                                background-color: #ebebeb;
                                @media (min-width: 767px) {
                                    margin-right: 24px;
                                }
                            `}
                        >
                            <Grid container p={2}>
                                <Grid item xs={12}>
                                    <OptimizedImage
                                        src={`${offerDetails.image}`}
                                        alt={offerDetails["H1 Title"] ?? offerDetails.title}
                                        width={"100%"}
                                    />
                                </Grid>
                                <Grid
                                    className={classes.offerContainer}
                                    item
                                    xs={12}
                                >
                                    <Button
                                        onClick={handleOpenEmail}
                                        className={classes.offerButton}
                                        css={css`
                                            border: 2px solid #1e4d71;
                                            width: 150px;
                                            color: #1e4d71;
                                            background: #fff;
                                            font-size: 1.25rem;
                                            margin-left: 12px;
                                        `}
                                    >
                                        {`EMAIL`}
                                    </Button>
                                    <Button
                                        className={classes.offerButton}
                                        onClick={handleOpenText}
                                        css={css`
                                            border: 2px solid #1e4d71;
                                            width: 150px;
                                            color: #1e4d71;
                                            background: #fff;
                                            font-size: 1.25rem;
                                            margin-left: 12px;
                                        `}
                                    >
                                        {`TEXT`}
                                    </Button>
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography
                                        css={css`
                                            font-size: 1rem;
                                            color: #595959;
                                            margin-top: 1rem;
                                        `}
                                    >
                                        {offerDetails.Disclaimer ?? offerDetails.disclaimerContent}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sm={4}>
                        <ScheduleAppointment />
                    </Grid>
                </Grid>{" "}
                <Services offerDetails={offerDetails} />
            </Grid>
            <Modal
                open={openEmail}
                onClose={handleCloseEmail}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container>
                        <Grid item xs={12}>
                            <HighlightOff
                                css={css`
                                    position: absolute;
                                    right: -9px;
                                    top: 6px;
                                    width: 60px;
                                    height: 30px;
                                    cursor: pointer;
                                `}
                                onClick={handleCloseEmail}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Email
                                disclaimer={EMAIL_MSG}
                                offers={[couponPayload(offerDetails)]}
                                handleCloseEmail={handleCloseEmail}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openText}
                onClose={handleCloseText}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container>
                        <Grid item xs={12}>
                            <HighlightOff
                                css={css`
                                    position: absolute;
                                    right: -9px;
                                    top: 6px;
                                    width: 60px;
                                    height: 30px;
                                    cursor: pointer;
                                `}
                                onClick={handleCloseText}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Phone
                                disclaimer={TEXT_MSG}
                                offers={[couponPayload(offerDetails)]}
                                handleCloseText={handleCloseText}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

export default OffersPage
